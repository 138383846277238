.About {
    box-sizing: border-box;
    border-radius: 0px 0px 24px 24px;
}


.AboutPicMobile {
    display: none;
    max-width: 100%;
    padding-top: 12px;
}

.Title {
    color: var(--primary-black, #191919);
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -1.92px;
}

.Developer {
    font-weight: 500;
}

.Studio {
    background-color: #F1CDCD;
    padding: 4px;
    font-weight: 500;
}

.SolidBox {
    margin-right: 4px;
    font-size: 24px;
}

.SolidBox:Hover {
    background-color: #EAE7AC;
}

.AboutPic {
    max-width: 100%;
    padding-top: 40px;
}

.Experience2 {
    padding-top: 144px;
    display: flex;
    justify-content:space-between;
    background-color:'#f0f0f0';
    position: relative;
    z-index: 1;
  }


.PageWrapperAbout {
    padding-top: 160px;
    padding-bottom: 160px;
    padding-right: 40px;
    padding-left: 40px;
    position: relative;
    background-color: #f0f0f0;
    z-index: 7;
    border-radius: 0px 0px 24px 24px;
  }
  