* {
    padding: 0;
    margin: 0;
  }

  .furkan {
    text-decoration: none;
    color: black;
    margin-bottom: 0;
    font-size: 20px;
  }

  .logoButton {
    background-color: #f0f0f0;
    border: none;
  }

  .logoLocation {
    font-size: 14px;
  }

  .Hamburger {
    display: none!important;
    background-color: #f0f0f0;
  } 

  .HamburgerButton {
    border: none;
    background-color: #f0f0f0;
  }

  .AppNavbar {
    background-color: #f0f0f0;

  }

  .Cursor {
    background-color: black;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9;
  }
  
  .SecondCursor {
    background-color: white;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 8;
  }
  

  .Links {
    position:fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 7;
  }

  .Router {
    display: inline-flex;
    padding: 4px 8px;
    align-items: flex-start;
    border-radius: 100px;
    background-color: white;
    list-style-type: none;
  }
  
  .Router li {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;
    background-color: white;
    display: flex;
    align-items: flex-start;
    border-radius: 100px;
  }
  
  .Router li a {
   color: black;
   text-decoration: none;
   background-color: white;
   padding: 8px 12px;
   border-radius: 100px;
  }

  .ButtonContact {
  background-color: white;
  border-radius: 100px;
  border: none;
  color: black;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.14px;
  }
  
  .Router li a:focus {
    background-color: black;
    color:white;
    border-radius: 100px;
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
  }
  
  .HeaderSocial h4 {
    font-size: 12px;
    color: #A0A1AA;
  }
  
  .SocialLinks {
    list-style-type: none;
    display: flex;
    gap: 12px;
  }
  
  .SocialLinks li a {
    color: black;
    text-decoration: none;
  }
  
  .SocialLinks li a:hover {
    text-decoration: underline;
  }

  .TopText {
    color: var(--primary-white, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.12px;
  }
  
  
  
  .MidQue {
    color: var(--primary-white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;
  }
  
  .MidButton {
    all: unset;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; 
    letter-spacing: -1.92px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    transform: translate3d(0px, -10%, 0px);
    transition-duration: 500ms;
  }
  
  .BottomText {
    color: var(--primary-grey, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.12px;
  }
  
  
  .BottomButton {
    all: unset;
    color: white;
    border-radius: 100px;
    border: 1px Solid white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 16px;
    margin:8px;
    
  }

  .footer {
    position: relative;
    z-index: 0;
    margin-top:-40px;
    background-color: #191919;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }



  
