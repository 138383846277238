.FirstSpan {
    background-color: #F1CDCD;
    padding: 4px;
  }
  
  .SecondSpan {
    background-color: #D4F4FB;
    padding:4px;
  }

  .PageWrapper {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-right: 40px;
    padding-left: 40px;
    position: relative;
    background-color: #f0f0f0;
    z-index: 7;
  }

  .ProjectDiv {
    padding-left: 40px;
    padding-right: 40px;
    background-color: #f0f0f0;
    padding-bottom: 120px;
    border-radius: 0px 0px 24px 24px;
    
  }